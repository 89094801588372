import { render, staticRenderFns } from "./FiltersAccordion.vue?vue&type=template&id=ca91fa7a&scoped=true"
import script from "./FiltersAccordion.vue?vue&type=script&lang=js"
export * from "./FiltersAccordion.vue?vue&type=script&lang=js"
import style0 from "./FiltersAccordion.vue?vue&type=style&index=0&id=ca91fa7a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca91fa7a",
  null
  
)


    import installComponents from "!/home/gitlab-runner/builds/_LQxgyuP/0/skolkovo/frontend/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BButton, BFormCheckbox, BFormCheckboxGroup, BCollapse} from 'bootstrap-vue'
    installComponents(component, {BButton, BFormCheckbox, BFormCheckboxGroup, BCollapse})
    

export default component.exports