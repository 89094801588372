<template>
	<div>
		<div
			class="filter-item-header nav-link"
			role="button"
			@click="toggleHandler"
		>
			<div class="d-flex align-items-center">
				{{ groupOfOptions.name }}
			</div>
			<div class="d-flex position-relative">
				<b-button
					v-if="selectedOptionsInGroup.length"
					type="button"
					class="btn btn-outline-primary btn-sm icon-close btn-sm-close"
					@click.stop="selectOption([])"
				/>
				<div
					class="dropdown-toggle d-flex align-items-center"
					:class="isOpen && 'rotate-arrow'"
				/>
			</div>
		</div>

		<b-collapse
			:id="'group__' + groupOfOptions.id"
			v-model="isOpen"
			role="tabpanel"
			class="events-accordion-collapse"
		>
			<ul class="nav-custom">
				<li class="nav-item-none">
					<b-form-checkbox-group
						v-model="selectedOptionsInGroup"
						stacked
						@change="selectOption($event)"
					>
						<b-form-checkbox
							v-for="option in groupOfOptions.options"
							:key="option.id"
							:value="option.id"
							class="mb-1"
						>
							{{ option.name }}
						</b-form-checkbox>
					</b-form-checkbox-group>
				</li>
			</ul>
		</b-collapse>
	</div>
</template>

<script>
import _difference from 'lodash/difference';
import _without from 'lodash/without';
import _intersection from 'lodash/intersection';
import checkScreenWidth from '@/mixins/checkScreenWidth';
import { BOOTSTRAP_BREAKPOINTS } from '@/utils/dictionaries';

export default {
	name: 'FiltersAccordion',
	mixins: [checkScreenWidth],
	inject: [
		'setSelectedOptions',
		'deleteFromSelectedOptions',
		'groupData',
		'optionsData',
		'taxonomyData',
		'setFilter',
	],
	props: {
		groupOfOptions: {
			type: Object,
			default: () => {
			},
		},
		whichFilterIsOpen: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			BOOTSTRAP_BREAKPOINTS,
			selectedOptionsInGroup: [],
			isLoading: false,
			isOpen: false,
		};
	},
	watch: {
		'$route.query.taxonomyOptions': {
			handler(newVal, oldVal) {
				if (this.groupOfOptions.id === 16 && (newVal.length < oldVal.length)) {
					const diff = _difference(oldVal, newVal).map((el) => Number(el));

					if (diff.length) {
						const filtered = _without(this.selectedOptionsInGroup, ...diff);
						this.selectedOptionsInGroup = filtered;
					}
				}
			},
		},
		screenWidth(val) {
			const ids = this.groupOfOptions.options.map((el) => el.id);
			const common = _intersection(this.optionsData.selectedOptions, ids);
			this.selectedOptionsInGroup = common;

			if (val >= BOOTSTRAP_BREAKPOINTS.lg) {
				this.isOpen = true;
			} else {
				this.isOpen = false;
			}
		},
		whichFilterIsOpen(val) {
			if (this.groupOfOptions.id === val) {
				this.isOpen = true;
			} else {
				this.isOpen = false;
			}
		},
		selectedOptionsInGroup: {
			handler(newArray, oldArray) {
				this.isLoading = true;
				if (oldArray.length > newArray.length) {
					const el = _difference(oldArray, newArray);
					this.deleteFromSelectedOptions(el, this.groupOfOptions.id);
				} else {
					this.setSelectedOptions(this.selectedOptionsInGroup);
				}
				this.setFilter('page', 1);
				this.isLoading = false;
			},
		},
	},
	created() {
		if (this.$route.query.taxonomyOptions && this.$route.query.taxonomyOptions.length) {
			const ids = this.groupOfOptions.options.map((el) => el.id);
			const queryIds = this.$route.query.taxonomyOptions.map((el) => Number(el));

			const common = _intersection(queryIds, ids);
			this.selectedOptionsInGroup = common;
		}
	},
	methods: {
		selectOption(val) {
			this.selectedOptionsInGroup = val;
		},
		toggleHandler() {
			if (this.screenWidth >= BOOTSTRAP_BREAKPOINTS.lg) {
				this.isOpen = !this.isOpen;
			} else {
				this.$emit('setOpenFilter', this.groupOfOptions.id);
			}
		},
	},
};
</script>

<style lang="scss" scoped>

@import "assets/styles/sk_variables";
@import "assets/styles/bootstrap/scss/functions";
@import "assets/styles/bootstrap/scss/variables";
@import "assets/styles/bootstrap/scss/mixins";

.events-accordion-collapse {
	@include media-breakpoint-up(lg) {
		max-height: 100%;
		overflow: auto;
		height: 100%;
	}

	&::-webkit-scrollbar {
		width: 5px;
	}

	&::-webkit-scrollbar-track {
		background: #FFFFFF;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 20px;
		background: $blue;
	}

	.btn-primary {
		line-height: 0.9;
		padding: 0.375rem;

		@include media-breakpoint-up(sm) {
			padding: 0.375rem 0.5rem;
			line-height: 1;
		}
	}

	.nav-item-none {
		cursor: pointer;
	}

	.nav-item-none::marker {
		content: none;
	}
}

.filter-item-header {
	position: relative;
	display: flex;
	align-items: center;
	padding: 1rem 1.2rem;
	outline: none;
	cursor: pointer;
	font-weight: 700;
	justify-content: space-between;
	border-top: 1px solid #F0F0F5;
	border-bottom: 1px solid #F0F0F5;

	&:first-child {
		border-top: none;
	}

	&:last-child {
		border-bottom: none;
	}

	.spinner-border {
		border-width: 0.15em;
	}
}

.filter-num {
	font-weight: normal;
}

.btn-sm {
	padding: 0.1rem 0.5rem;
	font-size: 0.7rem;
}

.nav-custom {
	padding: 1.25rem 1.25rem 0.75rem !important;
	list-style: none;
}

.icon-close {
	position: absolute;
	top: 50%;
	right: 1.7rem;
	transform: translateY(-50%);
	border-color: $blue;
	background-color: transparent;
}

.rotate-arrow {
	transform: rotate(180deg);
}
</style>
