<template>
	<div>
		<short-header />
		<div ref="sticky-container">
			<navigator-header />
			<navigator-body />
		</div>
		<main-footer />
	</div>
</template>

<script>

import { TYPOLOGY } from '@/utils/dictionaries';
import ShortHeader from '~/components/layouts/headers/ShortHeader.vue';
import NavigatorHeader from '~/components/navigator-v2/NavigatorHeader.vue';
import NavigatorBody from '~/components/navigator-v2/NavigatorBody.vue';
import MainFooter from '~/components/layouts/footers/MainFooter.vue';
import makeCanonicalLink from '~/utils/seo/makeCanonicalLink';

export default {
	name: 'Index',
	components: {
		NavigatorBody,
		NavigatorHeader,
		ShortHeader,
		MainFooter,
	},
	provide() {
		const appData = {};
		const groupData = {};
		const taxonomyData = {};

		Object.defineProperty(appData, 'events', {
			enumerable: true,
			get: () => this.events,
		});

		Object.defineProperty(appData, 'filters', {
			enumerable: true,
			get: () => this.filters,
		});

		Object.defineProperty(groupData, 'groups', {
			enumerable: true,
			get: () => this.groups,
		});

		Object.defineProperty(groupData, 'isLoading', {
			enumerable: true,
			get: () => this.isLoading,
		});

		Object.defineProperty(taxonomyData, 'taxonomies', {
			enumerable: true,
			get: () => this.taxonomies,
		});

		Object.defineProperty(taxonomyData, 'currentTaxonomy', {
			enumerable: true,
			get: () => this.currentTaxonomy,
			set: (value) => {
				this.currentTaxonomy = value;
			},
		});

		return {
			appData,
			groupData,
			taxonomyData,
			setFilter: (filter, value) => {
				this.filters[filter] = value;
			},
		};
	},
	async asyncData({ app }) {
		const { $api } = app;

		const [taxonomies, groups, events] = await Promise.all([
			$api.getEventTaxonomies(app.i18n.locale, { typologies: [TYPOLOGY.PROGRAM] }),
			$api.getEventTaxonomiesGroups(app.i18n.locale, { typologies: [TYPOLOGY.PROGRAM] }),
			$api.getEvents(app.i18n.locale, { typologies: [TYPOLOGY.PROGRAM] }),
		]);

		return {
			taxonomies: taxonomies.data,
			groups: groups.data,
			events,
		};
	},
	data() {
		return {
			filters: {
				page: 1,
				search: '',
				taxonomy: null,
				taxonomyOptions: [],
			},
			taxonomies: {},
			currentTaxonomy: null,
			groups: {},
			events: {},
			isLoading: false,
		};
	},
	head() {
		return {
			link: [
				{
					rel: 'canonical',
					hid: 'canonical',
					href: makeCanonicalLink(this.$route.path),
				},
			],
		};
	},
	watch: {
		filters: {
			handler(filters) {
				this.$router.push({ query: filters });
			},
			deep: true,
		},
		'$route.query': {
			async handler(query) {
				if (query) {
					try {
						this.filters.page = query.page ?? 1;
						this.filters.taxonomyOptions = query.taxonomyOptions ?? [];
						this.filters.search = query.search ?? '';
						this.filters.taxonomy = query.taxonomy ?? null;

						this.isLoading = true;
						const obj = {};
						this.filters.taxonomyOptions.forEach((el) => {
							obj[el] = true;
						});

						const request = { ...this.filters, taxonomyOptions: obj };

						const events = await this.$api
							.getEvents(this.$i18n.locale, { ...request, typologies: [TYPOLOGY.PROGRAM] });
						this.events = events;

						const { data } = await this.$api.getEventTaxonomiesGroups(
							this.$i18n.locale,
							{ ...request, typologies: [TYPOLOGY.PROGRAM] },
						);
						this.groups = data;

						this.isLoading = false;
					} catch (err) {
						console.log(err);
					}
				}
			},
			deep: true,
			immediate: true,
		},
	},
};
</script>

<style scoped>

</style>
